:root{
    --bs-table-color: #fff;
    --bs-table-bg: #071f40 !important;
    --bs-table-border-color: #373b3e;
    --bs-table-striped-bg: #2c3034;
    --bs-table-striped-color: #fff;
    --bs-table-active-bg: #373b3e;
    --bs-table-active-color: #fff;
    --bs-table-hover-bg: #323539;
    --bs-table-hover-color: #fff;
}
.table-dark{
    --bs-table-bg: #071f40 !important;
}